
import { defineComponent, ref } from "vue";
import {
  Row,
  Col,
  Input,
  InputPassword,
  Button,
  notification,
  message,
} from "ant-design-vue";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { addLocalStorage } from "@/utils/local_storage";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    Row,
    Col,
    Input,
    InputPassword,
    Button,
    EyeTwoTone,
    EyeInvisibleOutlined,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const userLogin = ref<string>("");
    const userPassword = ref<string>("");
    const handleLogin = async () => {
      const body = {
        login: userLogin.value,
        password: userPassword.value,
      };
      const result = await fetch(
        `${process.env.VUE_APP_BACKEND_URL}/admin/login`,
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const data = await result.json();
      if (result.ok) {
        // console.log(data);
        // notification.open({
        //   message: "Вы успешно вошли в систему",
        // });
        addLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_KEY, "true");
        addLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_JWT, data.authToken);
        store.dispatch("setProfile", data.admin);
        message.success({ content: "Вы успешно вошли в систему" });
        router.push({ name: "home" });
        // setTimeout(() => {
        // router.go(0);
        // }, 3000);
      } else {
        message.error({ content: data.error });
        // notification.open({
        //   message: data.error,
        // });
      }
    };
    return { userLogin, userPassword, handleLogin };
  },
});
