<template>
  <Row>
    <Col :span="24">
      <img src="../assets/logorevive.png" width="140" height="140" alt="" />
    </Col>
    <Col :span="24">Главная</Col>
    <Col :span="12" :offset="6">
      <div><b>Приветствуем на проекте.</b></div>
      <div>
        Пометка по найстройкам: Координата Y. если не указать эту координату -
        игра бдует расчитывать её автоматически относительно террэйна.
      </div>
    </Col>
  </Row>
</template>
<script>
import { defineComponent } from "vue";
import { Row, Col } from "ant-design-vue";

export default defineComponent({
  components: { Row, Col },
  setup() {},
});
</script>
