import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-5" }
const _hoisted_2 = {
  key: 2,
  style: {"position":"absolute","width":"100%"}
}
const _hoisted_3 = {
  key: 3,
  style: {"position":"absolute","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_Registration = _resolveComponent("Registration")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(" 111 "),
    (_ctx.isLoginComponent)
      ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
      : (_openBlock(), _createBlock(_component_Registration, { key: 1 })),
    (_ctx.isLoginComponent)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createTextVNode(" Нажмите "),
          _createElementVNode("span", {
            class: "auth_link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChangeLoginComponent && _ctx.handleChangeLoginComponent(...args)))
          }, "сюда"),
          _createTextVNode(" чтобы зарегистрироваться ")
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _createTextVNode(" Уже есть аккаунт? "),
          _createElementVNode("span", {
            class: "auth_link",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeLoginComponent && _ctx.handleChangeLoginComponent(...args)))
          }, "Перейти"),
          _createTextVNode(" на страницу входа ")
        ]))
  ]))
}