
import { defineComponent, ref } from "vue";
import {
  Row,
  Col,
  Input,
  InputPassword,
  Button,
  notification,
  Tooltip,
} from "ant-design-vue";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { addLocalStorage } from "@/utils/local_storage";

export default defineComponent({
  components: {
    Row,
    Col,
    Input,
    InputPassword,
    Button,
    Tooltip,
    EyeTwoTone,
    EyeInvisibleOutlined,
  },
  setup() {
    const router = useRouter();
    const userLogin = ref<string>("Izja");
    const userPassword = ref<string>("123456");
    const userToken = ref<string>(
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfbnVtYmVyIjoxMTEsImNsaWVudF9uYW1lIjoiVmFzeXoifQ.pP-Xs5H_nVb2kcCOYmg1dAM2QpAqQeKk-22aPxEbhb8"
    );
    const user_discord_guild = ref<string>("1213123123123123123");
    const userServerIp = ref<string>("1.1.1.1");
    console.log(process.env.VUE_APP_BACKEND_URL);
    const handleRegister = async () => {
      const body = {
        login: userLogin.value,
        password: userPassword.value,
        api_token: userToken.value,
        server_ip: userServerIp.value,
        discord_guild: user_discord_guild.value,
      };
      const result = await fetch(`${process.env.VUE_APP_BACKEND_URL}/admin`, {
        method: "POST",
        body: JSON.stringify(body),
      });
      if (result.ok) {
        notification.open({
          message: "Успешно создан пользователь",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        const data = await result.json();
        notification.open({
          message: `${data.message}`,
        });
      }
    };
    return {
      handleRegister,
      userLogin,
      userPassword,
      userToken,
      userServerIp,
      user_discord_guild,
    };
  },
});
