import { createRouter, createWebHistory } from "vue-router";
import MainPage from "../pages/MainPage.vue";
import TeleportPage from "../pages/TeleportPage.vue";
import TradePage from "../pages/TradePage.vue";
import GuidePage from "../pages/GuidePage.vue";
import AuthPage from "../pages/AuthPage.vue";
import AdminPage from "../pages/AdminPage.vue";
import LinkedTeleportPage from "../pages/LinkedTeleportPage.vue";
export const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: "/",
      name: "home",
      component: MainPage,
    },
    {
      path: "/teleport",
      name: "teleport",
      component: TeleportPage,
    },
    {
      path: "/traders",
      name: "traders",
      component: TradePage,
    },
    {
      path: "/guides",
      name: "guides",
      component: GuidePage,
    },
    {
      path: "/auth",
      name: "auth",
      component: AuthPage,
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminPage,
    },
    {
      path: "/linked-teleport",
      name: "linked-teleport",
      component: LinkedTeleportPage,
    },
  ],
});
