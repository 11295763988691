
import { defineComponent, ref } from "vue";
import Login from "./UI/Login.vue";
import Registration from "./UI/Registration.vue";
export default defineComponent({
  components: { Registration, Login },
  setup() {
    const isLoginComponent = ref<boolean>(true);
    const handleChangeLoginComponent = () => {
      isLoginComponent.value = !isLoginComponent.value;
    };
    return { isLoginComponent, handleChangeLoginComponent };
  },
});
