
import { defineComponent, ref, watchEffect } from "vue";
import { Tooltip, Input, Row, Col, Button, Checkbox } from "ant-design-vue";

export default defineComponent({
  components: { Tooltip, Input, Row, Col, Button, Checkbox },
  props: ["profile", "server"],
  emits: ["saveChangesServer", "saveNewServer"],
  setup(props, { emit }) {
    const server_ip = ref<string>("");
    const isActive = ref<boolean>(false);
    watchEffect(() => {
      server_ip.value = props?.server.server_ip ?? props?.profile.server_ip;
      isActive.value = props?.server.isActive ?? false;
    });
    const saveNewServer = () => {
      const new_dayzServer = {
        server_ip: server_ip.value,
        isActive: isActive.value,
      };
      emit("saveNewServer", new_dayzServer);
    };
    const saveChangesServer = () => {
      emit("saveChangesServer", {
        server_ip: server_ip.value,
        isActive: isActive.value,
      });
    };
    return {
      server_ip,
      isActive,
      saveNewServer,
      saveChangesServer,
    };
  },
});
