
import { defineComponent, onMounted, ref } from "vue";
import LinkedTeleportNew from "@/components/linked_teleport/UI/LinkedTeleportNew.vue";
import { getLocalStorage } from "@/utils/local_storage";
import { fetchApi } from "@/utils/fetch_api";
import { Row, Col } from "ant-design-vue";

export default defineComponent({
  components: { LinkedTeleportNew, Row, Col },
  setup() {
    const triggers = ref([]);
    onMounted(async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/linked-teleport`,
        "GET"
      );
      triggers.value = data;
      console.log(triggers.value);
    });
    const onDelete = async (_id: string) => {
      const response = await fetch(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/trigger-coords/` + _id,
        { method: "DELETE" }
      );
      const data = await response.json();
      triggers.value = data;
    };
    const saveChanges = async (coord: any) => {
      console.log(coord);
      const response = await fetch(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/trigger-coords`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ coord }),
        }
      );
      const data = await response.json();
      triggers.value = data;
    };
    return { triggers, onDelete, saveChanges };
  },
});
