<template>
    <MainTraders />
</template>
<script>
import { defineComponent } from 'vue';
import MainTraders from '../components/traders/MainTraders.vue'
export default defineComponent({
    components: { MainTraders },
    setup() {

    },
})
</script>