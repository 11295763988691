import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ProfileSettings = _resolveComponent("ProfileSettings")!
  const _component_ServerSettings = _resolveComponent("ServerSettings")!
  const _component_AppSettings = _resolveComponent("AppSettings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Menu, {
      mode: "horizontal",
      selectedKeys: _ctx.selectedKeys,
      "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
      items: _ctx.menuItems
    }, null, 8, ["selectedKeys", "items"]),
    (_ctx.selectedKeys[0] == '1')
      ? (_openBlock(), _createBlock(_component_ProfileSettings, {
          key: 0,
          profile: _ctx.profile,
          onSaveChangesProfile: _ctx.saveChangesProfile
        }, null, 8, ["profile", "onSaveChangesProfile"]))
      : _createCommentVNode("", true),
    (_ctx.selectedKeys[0] == '2')
      ? (_openBlock(), _createBlock(_component_ServerSettings, {
          key: 1,
          profile: _ctx.profile,
          server: _ctx.server,
          onSaveNewServer: _ctx.saveNewServer,
          onSaveChangesServer: _ctx.saveChangesServer
        }, null, 8, ["profile", "server", "onSaveNewServer", "onSaveChangesServer"]))
      : _createCommentVNode("", true),
    (_ctx.selectedKeys[0] == '3')
      ? (_openBlock(), _createBlock(_component_AppSettings, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}