
import { defineComponent } from "vue";
import LinkedTeleportCoords from "./UI/LinkedTeleportCoords.vue";
import { Layout, LayoutContent, Row, Col } from "ant-design-vue";

import { ref } from "vue";
export default defineComponent({
  components: {
    Layout,
    LayoutContent,
    Row,
    Col,
    LinkedTeleportCoords,
  },
  setup() {
    return {};
  },
});
