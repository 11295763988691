
import { defineComponent, onMounted, ref } from "vue";
import Exit from "@/components/teleport/UI/Exit.vue";
import ExitNew from "@/components/teleport/UI/ExitNew.vue";
import { fetchApi } from "@/utils/fetch_api";

export default defineComponent({
  components: { ExitNew, Exit },
  setup() {
    const triggers = ref([]);
    onMounted(async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/exit-coords`,
        "GET"
      );

      triggers.value = data;
      console.log(triggers.value);
    });
    const onDelete = async (_id: string) => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/exit-coords/` + _id,
       "DELETE"
      );
      triggers.value = data;
    };
    const saveChanges = async (coord: any) => {
      console.log(coord);
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/exit-coords`,
        "POST",
          coord
      );
      triggers.value = data;
    };
    return { triggers, onDelete, saveChanges };
  },
});
