import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Col = _resolveComponent("Col")!
  const _component_EyeTwoTone = _resolveComponent("EyeTwoTone")!
  const _component_EyeInvisibleOutlined = _resolveComponent("EyeInvisibleOutlined")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createBlock(_component_Row, null, {
    default: _withCtx(() => [
      _createVNode(_component_Col, {
        span: 12,
        offset: 6,
        class: "my-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Input, {
            value: _ctx.userLogin,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userLogin) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_Col, {
        span: 12,
        offset: 6,
        class: "my-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputPassword, {
            value: _ctx.userPassword,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userPassword) = $event))
          }, {
            iconRender: _withCtx((v) => [
              v
                ? (_openBlock(), _createBlock(_component_EyeTwoTone, { key: 0 }))
                : (_openBlock(), _createBlock(_component_EyeInvisibleOutlined, { key: 1 }))
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_Col, {
        span: 12,
        offset: 6,
        class: "my-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            onClick: _ctx.handleLogin,
            type: "primary",
            class: "mx-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Войти")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}