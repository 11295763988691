
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { Select, Row, Col, Switch, Tooltip } from "ant-design-vue";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
const { Option } = Select;

export default defineComponent({
  components: {
    Select,
    Row,
    Col,
    Switch,
    Option,
    Tooltip,
    CloseCircleOutlined,
  },
  props: ["equipment"],
  emits: ["selected_equipment"],
  setup(props, { emit }) {
    const store = useStore();
    const subcat = ref<string>("Выберите подкатегорию");
    const item = ref<string>("");
    const checked = ref<boolean>(false);
    const equipment = ref<any[]>(props.equipment.clothings);
    const equipment_classname = ref<string>("");
    const equipment_description = ref<string>("");
    const selectedOptions = ref<any>([]);
    const selectValue = ref<string | null>("");
    const subcategories = computed(() => store.state.subcategories);
    const items = computed(() => store.state.items);
    const loading = ref(true);
    const filteredOptions = computed(() => {
      return selectedOptions.value.filter((el: any) => el !== undefined);
    });
    /* const subcategories = computed(() => {
      return Array.from(
        new Set(equipment.value.map(({ subcategory }) => subcategory))
      );
    }); */
    const switches = ref(new Array(subcategories.value.length).fill(false));
    const onSelect = (value: any, key: any) => {
      equipment_classname.value = `Класснейм: ${key.key}`;
      equipment_description.value = `Описание: ${
        equipment.value.filter(({ classname }) => classname == key.key)[0]
          .description
      }`;
      emit("selected_equipment", subcat.value, value, key.key);
    };
    const onSubcatChange = () => {
      equipment_description.value = "";
      equipment_classname.value = "";
      item.value = "Выберите итем";
    };

    for (let i = 0; i < subcategories.value.length; i++) {
      switches.value.push(false);
    }

    const handleSwitchChange = (index: any, subcat: any) => {
      // Установить false для всех переключателей, кроме выбранного
      switches.value.forEach((_, idx) => {
        if (idx !== index) {
          switches.value[idx] = false;
        }
      });

      store.commit("resetItems");
      if (switches.value[index]) {
        store.dispatch("getClothingBySubcut", subcat);
      }
    };

    const handleSelectChange = (index: any, selectedValue: any) => {
      console.log(selectedValue);
      const selectedItem = store.state.items.find(
        (item: any) =>
          item.name === selectedValue.name &&
          item.classname === selectedValue.classname
      );

      if (selectedItem) {
        const findSameIndex = selectedOptions.value.findIndex(
          (obj: any) => obj.subcategory === selectedItem.subcategory
        );

        if (findSameIndex !== -1) {
          selectedOptions.value.splice(findSameIndex, 1);
        }

        selectedOptions.value = [...selectedOptions.value, selectedItem];
        store.commit("setEquipment", [...selectedOptions.value]);
      }
    };

    const removeItem = (index: any) => {
      selectedOptions.value.splice(index, 1);
    };

    const handleSelectedValueChange = (newValue: any) => {
      console.log("Значение selectedValue изменилось:", newValue);
      // Здесь вы можете выполнить нужные вам действия при изменении selectedValue
    };

    // Отслеживаем изменения selectedValue
    watch(selectValue, handleSelectedValueChange);

    onMounted(() => (selectedOptions.value = store.state.equipment));

    watch(
      () => store.state.equipment,
      (newValue) => {
        console.log(store.state.equipment);
        selectedOptions.value = newValue;
      }
    );

    function customFilter(input: any, option: any) {
      const itemKey = option.key.toLowerCase();
      const itemValue = option.value.toLowerCase();
      const inputLowerCase = input.toLowerCase();

      return (
        itemKey.includes(inputLowerCase) || itemValue.includes(inputLowerCase)
      );
    }

    return {
      subcategories,
      subcat,
      item,
      equipment,
      equipment_description,
      equipment_classname,
      onSelect,
      onSubcatChange,
      checked,
      switches,
      handleSwitchChange,
      selectedOptions,
      filteredOptions,
      items,
      loading,
      handleSelectChange,
      removeItem,
      selectValue,
      customFilter,
    };
  },
});
