
import { defineComponent, ref, watch } from "vue";
import { Input, Textarea, Button, Row, Col, Tooltip } from "ant-design-vue";
export default defineComponent({
  components: { Input, Textarea, Button, Row, Col, Tooltip },
  props: {
    triggerCoords: {
      type: Object,
    },
  },
  emits: ["onDelete", "saveChanges"],
  setup(props, { emit }) {
    const _id = ref<string>(props.triggerCoords?._id);
    const X = ref<number>(0);
    const Y = ref<number>(0);
    const Z = ref<number>(0);
    const nameCoord = ref<string>("б/и");
    const isChanged = ref<boolean>(false);
    const isSaved = ref<boolean>(false);
    const onFail = ref<boolean>(false);
    const description = ref<string>(props.triggerCoords?.description);
    const saveChanges = async () => {
      emit("saveChanges", {
        posX: X.value,
        posY: Y.value,
        posZ: Z.value,
        name: nameCoord.value,
        description: description.value,
      });
      X.value = 0;
      Y.value = 0;
      Z.value = 0;
      isSaved.value = true;
      setTimeout(() => {
        isSaved.value = false;
      }, 4000);
    };
    watch([X, Z], (nextValue, prevValue) => {
      if (nextValue[0] && prevValue[0]) {
        if (
          (nextValue[0].toString().indexOf(".") != -1 &&
            nextValue[0].toString().length > 8) ||
          (nextValue[1].toString().indexOf(".") != -1 &&
            nextValue[1].toString().length > 8) ||
          parseInt(nextValue[0].toString().split(".")[0]) > 20000 ||
          parseInt(nextValue[1].toString().split(".")[0]) > 20000
        ) {
          isChanged.value = true;
        } else {
          isChanged.value = false;
        }
      }
    });
    return {
      X,
      Y,
      Z,
      nameCoord,
      isChanged,
      isSaved,
      onFail,
      saveChanges,
      description,
    };
  },
});
