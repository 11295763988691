
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  watchEffect,
} from "vue";
import Equipment from "./Equipment.vue";
import {
  Input,
  Button,
  Modal,
  Textarea,
  notification,
  Row,
  Col,
  Tooltip,
  Switch,
  Space,
  Select,
  Form,
} from "ant-design-vue";
import { useStore } from "vuex";
import { fetchApi } from "@/utils/fetch_api";

// type TNpc = {
//   menu_type: number,
//   menu_id: number,
//   posX: number,
//   posZ: number,
//   coords_list: any[]
// }

export default defineComponent({
  components: {
    Input,
    Button,
    Modal,
    Textarea,
    notification,
    Row,
    Col,
    Tooltip,
    Switch,
    Equipment,
    Space,
    Select,
  },
  props: ["npc", "key"],
  emits: ["updated", "update_npc"],
  setup(props, { emit }) {
    const store = useStore();
    const selected_equip = ref<
      { subcategory: string; equip: string; classname: string }[]
    >([]);
    const npc = ref<any>(props?.npc);
    const menu_type = ref<number>(npc.value?.menu_type);
    const menu_id = ref<number>(npc.value?.menu_id);
    const npc_oldname = ref<string>(npc.value?.name);
    const npc_name = ref<string>(npc.value?.name);
    const npc_model = ref<string>(npc.value?.model);
    const npc_equipment = ref<string[]>(
      npc.value?.equipment.map((v: any) => {
        return `${v.name} (${v.classname})`;
      })
    );
    const npc_animation = ref<string[]>(npc.value?.animation);
    const posX = ref<number>(npc.value?.posX);
    const posY = ref<number>(npc.value?.posY);
    const posZ = ref<number>(npc.value?.posZ);
    const modalText = ref<string>("Content of the modal");
    const open = ref<boolean>(false);
    const openEquip = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const isValidate = ref<boolean>(true);
    const checked = ref<boolean>(false);
    const modalVisible = ref(false);
    const equipment = computed(() => store.state.equipment);
    const inputValue = ref<string>(npc.value?.dir || "0");
    //coords
    const name = ref<string>("");
    const c_posX = ref<number>();
    const c_posY = ref<number>();
    const c_posZ = ref<number>();
    const c_description = ref<string>("");
    const c_npc_id = ref<number>(menu_id.value);
    const c_price = ref<number | undefined>();
    //
    //npc

    //
    const update_npc = () => {
      const result_equip: any[] = [];
      if (selected_equip.value.length > 0) {
        selected_equip.value.forEach((v) =>
          list_equipment.value.clothings
            .filter((el: any) => el.classname == v.classname)
            .forEach((el2: any) => result_equip.push(el2._id))
        );
      }
      emit("update_npc", {
        _id: npc.value._id,
        menu_id: menu_id.value,
        name: npc_name.value,
        equipment: equipment.value,
        model: npc_model.value,
        posX: Number(posX.value),
        posY: Number(posY.value),
        posZ: Number(posZ.value),
        dir: inputValue.value,
      });
    };
    const list_equipment = computed(() => {
      return store.getters.getAccessibleEquipment;
    });
    watch(posX, (newVal, oldVal) => {
      if (newVal >= 0 && newVal <= 20000 && !isNaN(Number(newVal))) {
        isValidate.value = true;
      } else {
        isValidate.value = false;
      }
    });
    watch(posZ, (newVal, oldVal) => {
      if (newVal >= 0 && newVal <= 20000 && !isNaN(Number(newVal))) {
        isValidate.value = true;
      } else {
        isValidate.value = false;
      }
    });
    watch(posY, (newVal, oldVal) => {
      if (newVal >= 0 && newVal <= 20000 && !isNaN(Number(newVal))) {
        isValidate.value = true;
      } else {
        isValidate.value = false;
      }
    });
    watch(npc_name, (newVal, oldVal) => {
      const trimmedValue = newVal ? newVal.trim() : newVal;
      if (newVal == null || trimmedValue === "") {
        isValidate.value = false;
      } else {
        isValidate.value = true;
      }
    });
    const handleOk = async () => {
      confirmLoading.value = true;
      const new_coord = {
        name: name.value,
        posX: Number(c_posX.value),
        posY: Number(c_posY.value),
        posZ: Number(c_posZ.value),
        description: c_description.value,
        npc_id: c_npc_id.value,
        price: c_price.value,
      };
      const response = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/coord`,
        "POST",
        new_coord
      );
      if (response) {
        notification.open({
          message: "Точка создана и добавлена проводнику",
        });
        emit("updated");
      } else {
        notification.open({
          message: `Ошибка ${response.text()}`,
        });
      }
      open.value = false;
      confirmLoading.value = false;
    };
    const selected_equipment = (
      subcategory: string,
      equip: string,
      classname: string
    ) => {
      if (selected_equip.value.some((e) => e.subcategory === subcategory)) {
        selected_equip.value = selected_equip.value.filter(
          (v) => v.subcategory !== subcategory
        );
      }
      selected_equip.value.push({ subcategory, equip, classname });
    };
    const showModal = () => {
      open.value = true;
    };
    const showModalEquip = () => {
      openEquip.value = true;
    };
    const handleOkEquip = () => {
      openEquip.value = false;
    };

    const openModal = () => {
      modalVisible.value = true;
    };

    const closeModal = () => {
      modalVisible.value = false;
    };

    const save = () => {
      modalVisible.value = false;
    };

    const cancel = () => {
      store.commit("resetEquipment");
      console.log(equipment);
    };

    onMounted(() => {
      store.dispatch("getSubcategories");
      store.commit("setEquipment", npc.value.equipment);
      console.log(name);
    });

    const showNotification = () => {
      notification.open({
        message: "Заголовок уведомления",
        description: "Введите пожалуйста величину в градусах(от 0 до 359)",
        duration: 4,
        placement: "topRight",
        type: "info",
      });
    };

    const handleInput = (event: any) => {
      let value = event.target.value;
      if (/^[0-9]+$/.test(value)) {
        const number = parseInt(value);
        if (number >= 0 && number <= 359) {
          inputValue.value = value;
        } else {
          inputValue.value = "";
          showNotification();
        }
      } else {
        inputValue.value = "";
      }
    };

    return {
      checked,
      menu_type,
      menu_id,
      npc_name,
      npc_oldname,
      npc_equipment: npc_equipment.value.join(";"),
      npc_model,
      posX,
      posY,
      posZ,
      showModal,
      confirmLoading,
      modalText,
      handleOk,
      update_npc,
      open,
      name,
      c_posX,
      c_posY,
      c_posZ,
      c_description,
      c_npc_id,
      c_price,
      isValidate,
      list_equipment,
      selected_equip,
      selected_equipment,
      openEquip,
      handleOkEquip,
      showModalEquip,
      modalVisible,
      closeModal,
      openModal,
      save,
      equipment,
      cancel,
      handleInput,
      inputValue,
    };
  },
});
