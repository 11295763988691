import { delLocalStorage, getLocalStorage } from "./local_storage";

export async function fetchApi(url: string, method: string, payload?: any) {
  const token = getLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_JWT);
  console.log(payload ? JSON.stringify(payload) : undefined);
  const response = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: payload ? JSON.stringify(payload) : undefined,
  });
  const data = await response.json();
  console.log(data);
  if (!data || data.statusCode == 401) {
    delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_JWT);
    delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_KEY);
    //window.location.reload();
    return undefined;
  }
  if (data.statusCode == 400 || data.statusCode == 500) {
    return undefined;
  }
  return data;
}
