
import { defineComponent, onMounted, ref, watch } from "vue";
import { Button, Row, Col, notification, Menu, MenuItem } from "ant-design-vue";
import { delLocalStorage } from "@/utils/local_storage";
import { useRouter } from "vue-router";
import ProfileSettings from "./UI/ProfileSettings.vue";
import ServerSettings from "./UI/ServerSettings.vue";
import AppSettings from "./UI/AppSettings.vue";
import { fetchApi } from "@/utils/fetch_api";
import { TDayzServer } from "@/types/dayzserver.type";
import { TEntitiesClient } from "@/types/entitiesclient.type";

type TProfile = {
  _id: string;
  GUID: string;
  login: string;
  steam_id: string;
  discord_name: string;
  discord_real_name: string;
  isSuperAdmin: boolean;
  discord_guild: string;
  server_ip: string;
};

export default defineComponent({
  components: {
    Button,
    Row,
    Col,
    ProfileSettings,
    ServerSettings,
    AppSettings,
    Menu,
    MenuItem,
  },
  setup() {
    const menuItems = [
      { key: "1", label: `Настройки профиля`, title: "Profile settings" },
      { key: "2", label: `Настройки сервера`, title: "Profile server" },
      { key: "3", label: `Настройки приложения`, title: "Application server" },
      { key: "4", label: `Выйти`, title: "Logout" },
    ];
    const router = useRouter();
    const selectedKeys = ref<string[]>(["1"]);
    const profile = ref<TProfile | any>({});
    const server = ref<TDayzServer | any>({});
    /* const handleLogout = () => {
      if (selectedKeys.value[0] == "4") {
        console.log("Выход");
        delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_KEY);
        delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_JWT);
        router.go(0);
      }
    }; */
    onMounted(async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/admin/profile`,
        "GET"
      );
      notification.open({
        message: "Получен профиль",
      });
      profile.value = data.admin;
      if (data.dayzServer) {
        server.value = data.dayzServer;
      }
    });
    const saveChangesProfile = async (upd_profile: TProfile) => {
      profile.value = { ...profile.value, ...upd_profile };
      const response = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/admin`,
        "PUT",
        profile.value
      );
      notification.open({
        message: "Профиль обновлён",
      });
    };
    const saveChangesServer = async (upd_dayz_server: TDayzServer) => {
      await fetchApi(`${process.env.VUE_APP_BACKEND_URL}/dayzserver`, "PUT", {
        ...upd_dayz_server,
      });
      delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_JWT);
      delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_KEY);
      router.go(0);
    };
    const saveNewServer = async (new_dayz_server: TDayzServer) => {
      await fetchApi(`${process.env.VUE_APP_BACKEND_URL}/dayzserver`, "POST", {
        ...new_dayz_server,
        server_owner: profile.value._id,
      });
      router.go(0);
    };

    watch(selectedKeys, (newVal, oldVal) => {
      console.log(selectedKeys.value[0]);
      if (selectedKeys.value[0] == "4") {
        delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_KEY);
        delLocalStorage(process.env.VUE_APP_LOCAL_STORAGE_JWT);
        router.go(0);
      }
    });

    return {
      profile,
      server,
      menuItems,
      selectedKeys,
      saveChangesProfile,
      saveChangesServer,
      saveNewServer,
    };
  },
});
