import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoCircleOutlined = _resolveComponent("InfoCircleOutlined")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.sounds_npc_guide_allow,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sounds_npc_guide_allow) = $event)),
      placeholder: "Звуки при открытии меню",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор звуков, которые произносит проводник, когда открываешь\n          меню"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.sounds_npc_guide_deny,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sounds_npc_guide_deny) = $event)),
      placeholder: "Звуки отказа",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор звуков, которые произносит проводник, когда отказывает\n        игроку"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.sounds_trigger_trap,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sounds_trigger_trap) = $event)),
      placeholder: "Звук триггера",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор звуков, которые производит триггер-ловушка на близкой\n        дистанции"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.sounds_trigger_trap_wrapper,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sounds_trigger_trap_wrapper) = $event)),
      placeholder: "Звук триггера на дальней дистанции",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор звуков, которые производит триггер-ловушка на дальней\n        дистанции"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.particles_triggers_traps,
      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.particles_triggers_traps) = $event)),
      placeholder: "Партикл триггера",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор партиклов для триггера-ловушки"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.particles_triggers_traps_extends,
      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.particles_triggers_traps_extends) = $event)),
      placeholder: "Доп.партикл триггера",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор дополнительных партиклов для триггеров-ловушек"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.images_background_npc_guide,
      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.images_background_npc_guide) = $event)),
      placeholder: "Фоновые картинки при переходе через проводника",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор фоновых картинок при переходе через проводника"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.sounds_npc_guide_crossing,
      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sounds_npc_guide_crossing) = $event)),
      placeholder: "Звуки при переходе через проводника",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор звуков при переходе через проводника"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.players_icons,
      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.players_icons) = $event)),
      placeholder: "Иконки игрока в диалогах с проводниками",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор иконок игрока в диалогах с проводниками"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_component_Input, {
      type: "text",
      value: _ctx.guides_icons,
      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.guides_icons) = $event)),
      placeholder: "Иконки проводника в диалогах с проводниками",
      style: { marginTop: '10px', zIndex: 1, width: '60%' },
      class: "inputProfile"
    }, {
      suffix: _withCtx(() => [
        _createVNode(_component_Tooltip, {
          color: '#D45252',
          title: "Набор иконок проводника в диалогах с проводниками"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InfoCircleOutlined, { style: {"color":"rgba(0, 0, 0, 0.45)"} })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"]),
    _createElementVNode("div", null, [
      _createVNode(_component_Button, {
        style: { marginTop: '4px', zIndex: 1 },
        type: "primary",
        onClick: _ctx.updateAppSettings
      }, {
        default: _withCtx(() => [
          _createTextVNode("Сохранить изменения")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ], 64))
}