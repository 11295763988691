<template>
    Торговцы
</template>
<script>
import { defineComponent } from "vue"

export default defineComponent ({
    setup() {
        
    },
})
</script>