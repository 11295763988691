import { TAdmin } from "@/types/admin.type";
import { TItem } from "@/types/item.type";
import { fetchApi } from "@/utils/fetch_api";
import { createStore } from "vuex";

type TState = {
  isLoggedIn: boolean;
  accessible_equipment: TItem[];
  profile: TAdmin | undefined;
  subcategories: any[];
  items: any[];
  equipment: any[];
};

export const store = createStore({
  state: {
    isLoggedIn: false,
    accessible_equipment: [],
    profile: undefined,
    subcategories: [],
    items: [],
    equipment: [],
  },
  actions: {
    setLoggedIn({ commit }: any) {
      commit("setLoggedIn");
    },
    setLoggedOut({ commit }: any) {
      commit("setLoggedOut");
    },
    setAccessibleEquipment({ commit }: any, payload: TItem[]) {
      commit("setAccessibleEquipment", payload);
    },
    setProfile({ commit }: any, payload: TAdmin) {
      commit("setProfile", payload);
    },
    async getSubcategories({ commit }) {
      try {
        const response = await fetchApi(
          `${process.env.VUE_APP_BACKEND_URL}/consolidator-web`,
          "GET"
        );
        commit("getAllSubcategories", response);
        console.log(response);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async getClothingBySubcut({ commit }, subcat) {
      try {
        const response = await fetchApi(
          `${process.env.VUE_APP_BACKEND_URL}/consolidator-web/clothing/${subcat}`,
          "GET"
        );
        commit("getClothingBySubcut", response);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    setLoggedIn(state: TState) {
      state.isLoggedIn = true;
    },
    setLoggedOut(state: TState) {
      state.isLoggedIn = false;
    },
    setAccessibleEquipment(state: TState, payload: TItem[]) {
      state.accessible_equipment = payload;
    },
    setProfile(state: TState, payload: TAdmin) {
      console.log(payload);
      state.profile = payload;
    },
    getAllSubcategories(state: TState, data: any) {
      state.subcategories = data;
    },
    getClothingBySubcut(state: TState, data: any) {
      state.items = data;
    },
    resetItems(state: TState) {
      state.items = [];
    },
    resetEquipment(state: TState) {
      state.equipment = [];
    },
    setEquipment(state: TState, payload: any) {
      state.equipment = payload;
    },
  },
  getters: {
    isLoggedIn(state: TState) {
      return state.isLoggedIn;
    },
    getAccessibleEquipment(state: TState) {
      return state.accessible_equipment;
    },
    getProfile(state: TState) {
      return state.profile;
    },
  },
});
