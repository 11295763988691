
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  watch,
  watchEffect,
} from "vue";
import {
  Layout,
  LayoutContent,
  LayoutHeader,
  Menu,
  MenuItem,
  Button,
  Row,
  Col,
} from "ant-design-vue";
import { UserOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  getLocalStorage,
  addLocalStorage,
  delLocalStorage,
} from "./utils/local_storage";
import { TAdmin } from "./types/admin.type";
import { fetchApi } from "./utils/fetch_api";
import { TDayzServer } from "./types/dayzserver.type";

export default defineComponent({
  components: {
    Layout,
    LayoutHeader,
    Menu,
    MenuItem,
    LayoutContent,
    Button,
    UserOutlined,
    Row,
    Col,
  },
  setup() {
    const store = useStore();
    const profile = ref<{ admin: TAdmin; dayzServer: TDayzServer }>();
    const currentRout = ref<string>("");
    const isLoggedIn = computed(() => {
      const localStorageLogin = getLocalStorage(
        process.env.VUE_APP_LOCAL_STORAGE_KEY
      );

      currentRout.value = router.currentRoute.value.path;

      if (localStorageLogin) {
        store.dispatch("setLoggedIn");
        return store.getters.isLoggedIn;
      } else {
        router.push({ name: "auth" });
        return store.getters.isLoggedIn;
      }
    });
    watchEffect(() => {});
    onUpdated(async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/admin/profile`,
        "GET"
      );
      // console.log(data);
      if (data) {
        profile.value = data;
      }
      console.log("UPD");
    });
    onMounted(async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/validate`,
        "GET"
      );
      if (data.message == "Unauthorized") {
        router.push({ name: "auth" });
      }
    });
    onMounted(async () => {
      const localStorageLogin = getLocalStorage(
        process.env.VUE_APP_LOCAL_STORAGE_KEY
      );
      console.log("MNTD");
      if (localStorageLogin) {
        store.dispatch("setLoggedIn");
        router.push({ name: "home" });
      } else {
        router.push({ name: "auth" });
      }
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/admin/profile`,
        "GET"
      );
      if (data) {
        profile.value = data;
      }
    });

    const router = useRouter();
    const selectedKeys = ref<string[]>(["1"]);
    const goToTeleports = () => {
      router.push({ name: "teleport" });
    };
    const goToMain = () => {
      router.push({ name: "home" });
    };
    const goToTraders = () => {
      router.push({ name: "traders" });
    };
    const goToGuides = () => {
      router.push({ name: "guides" });
    };
    const goToAuth = () => {
      router.push({ name: "auth" });
    };
    const goToProfile = () => {
      selectedKeys.value = ["1"];
      console.log(selectedKeys.value);
      router.push({ name: "admin" });
    };
    const goToLinkedTeleport = () => {
      router.push({ name: "linked-teleport" });
    };
    const Select = ref<number>(0);
    const onSelect = (key: any) => {
      Select.value = Number(key.selectedKeys[0]);
    };

    watch(selectedKeys, (newVal, oldVal) => {
      console.log(newVal);
    });
    return {
      Select,
      onSelect,
      goToTeleports,
      goToMain,
      goToTraders,
      goToGuides,
      goToAuth,
      goToProfile,
      goToLinkedTeleport,
      selectedKeys,
      isLoggedIn,
      profile,
      currentRout,
    };
  },
});
