import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layout = _resolveComponent("Layout")!
  const _component_LinkedTeleportCoords = _resolveComponent("LinkedTeleportCoords")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Row = _resolveComponent("Row")!
  const _component_LayoutContent = _resolveComponent("LayoutContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Layout),
    _createVNode(_component_Layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_LayoutContent, null, {
          default: _withCtx(() => [
            _createVNode(_component_Row, null, {
              default: _withCtx(() => [
                _createVNode(_component_Col, {
                  xl: { span: 12, offset: 6 },
                  sm: { span: 14, offset: 5 },
                  xs: { span: 18, offset: 3 }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LinkedTeleportCoords)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}