
import { defineComponent, ref, watch } from "vue";
import {
  Input,
  Textarea,
  Button,
  Row,
  Col,
  Tooltip,
  Space,
  Checkbox
} from "ant-design-vue";
import { TEyeEffects, TTriggerCoords } from "@/types/triggercoords.type";
import { fetchApi } from "@/utils/fetch_api";

export default defineComponent({
  components: { Input, Textarea, Button, Row, Col, Tooltip, Space,Checkbox },
  props: {
    triggerCoords: {
      type: Object,
    },
  },
  emits: ["onDelete"],
  setup(props, { emit }) {
    console.log(props.triggerCoords?._id)
    const closedX = ref<number>(props.triggerCoords?.posX);
    const closedZ = ref<number>(props.triggerCoords?.posZ);
    const _id = ref<string>(props.triggerCoords?._id);
    const X = ref<number>(props.triggerCoords?.posX);
    const Y = ref<number>(props.triggerCoords?.posY);
    const Z = ref<number>(props.triggerCoords?.posZ);
    const description = ref<string>(props.triggerCoords?.description);
    const nameCoord = ref<string>(props.triggerCoords?.name);
    const isChanged = ref<boolean>(false);
    const isSaved = ref<boolean>(false);
    const onFail = ref<boolean>(false);
      const isEyeEffectEnabled = ref<Boolean>(props.triggerCoords?.eye_effects.isEnable as boolean);
    const isColoredRandomChange = ref<Boolean>(props.triggerCoords?.eye_effects.isColoredRandomChange as boolean);
    const isEyeRandomFreq = ref<Boolean>(props.triggerCoords?.eye_effects.isEyeRandomFreq as boolean);
    const argb = ref<Number[]>(props.triggerCoords?.eye_effects.colors as Number[]);
    const rgb_to_hex = (r: number, g: number, b: number) => {
      console.log(r,g,b)
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }
    const argb_hexed = (rgb_to_hex(parseInt(`${Number(argb.value[0]) * 100 * 2.55}`), parseInt(`${Number(argb.value[1]) * 100 * 2.55}`), parseInt(`${Number(argb.value[2]) * 100 * 2.55}`)))
    const result_obj = ref<TTriggerCoords>({} as TTriggerCoords)
    const toRGB = (e: any) => {
      let color;
      if(typeof e === "string"){
        color = e;
      }else{
        color = e.target.value;
      }
      const {style} = new Option();
      style.color = color;
      console.log(style.color.replace(/[^\d,]/g, "").split(",").map(v => Number((Number(v) /2.55 /100).toFixed(2))).concat([0]))
      argb.value = style.color.replace(/[^\d,]/g, "").split(",").map(v => Number((Number(v) /2.55 /100).toFixed(2))).concat([0]);
    }
    const saveChanges = async () => {
      let eye_effects: TEyeEffects = {
      isEnable:isEyeEffectEnabled.value as boolean,
      isColoredRandomChange: isColoredRandomChange.value as boolean,
      isEyeRandomFreq: isEyeRandomFreq.value as boolean,
      colors: argb.value as number[]
      }
      result_obj.value._id = _id.value as string;
      result_obj.value.posX = X.value;
      result_obj.value.posZ = Z.value;
      result_obj.value.name = nameCoord.value;
      result_obj.value.description = description.value;
      result_obj.value.eye_effects = eye_effects;
      // console.log(JSON.stringify({ _id: _id.value, posX: X.value, posZ: Z.value, name: nameCoord.value, description: description.value }))
      console.log(_id.value)
      console.log(result_obj.value)
      const response = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/trigger-coords`,
        
           "PUT",
         result_obj.value
      );
      const data = await response;
      if (data){
        
        X.value = data.posX;
        Y.value = data.posY;
        Z.value = data.posZ;
        isSaved.value = true;
        setTimeout(() => {
          isSaved.value = false;
        }, 4000);
      } else {
        onFail.value = true;
        setTimeout(() => {
          onFail.value = false;
        }, 4000);
      }
    };
    const onDelete = () => {
      emit("onDelete", _id.value);
    };
    watch([X, Z], (nextValue, prevValue) => {
      if (
        (nextValue[0].toString().indexOf(".") != -1 &&
          nextValue[0].toString().length > 8) ||
        (nextValue[1].toString().indexOf(".") != -1 &&
          nextValue[1].toString().length > 8) ||
        parseInt(nextValue[0].toString().split(".")[0]) > 20000 ||
        parseInt(nextValue[1].toString().split(".")[0]) > 20000
      ) {
        isChanged.value = true;
      } else {
        isChanged.value = false;
      }
    });
    return {
      X,
      Y,
      Z,
      nameCoord,
      isChanged,
      isSaved,
      onFail,
      description,
      saveChanges,
      onDelete,
      isEyeEffectEnabled,
      isColoredRandomChange,
      isEyeRandomFreq,
      argb,
      toRGB,
      argb_hexed
    };
  },
});
