import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_TriggerCoords = _resolveComponent("TriggerCoords")!
  const _component_ExitCoords = _resolveComponent("ExitCoords")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Row = _resolveComponent("Row")!
  const _component_LayoutContent = _resolveComponent("LayoutContent")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Menu, {
      mode: "horizontal",
      style: {
      zIndex: 3,
      background: 'transparent',
    },
      selectedKeys: _ctx.selectedKeys,
      "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
      items: _ctx.items
    }, null, 8, ["selectedKeys", "items"]),
    _createVNode(_component_Layout, { style: {} }, {
      default: _withCtx(() => [
        _createVNode(_component_LayoutContent, null, {
          default: _withCtx(() => [
            _createVNode(_component_Row, null, {
              default: _withCtx(() => [
                _createVNode(_component_Col, {
                  xl: { span: 12, offset: 6 },
                  sm: { span: 14, offset: 5 },
                  xs: { span: 18, offset: 3 }
                }, {
                  default: _withCtx(() => [
                    (_ctx.selectedKeys[0] == '1')
                      ? (_openBlock(), _createBlock(_component_TriggerCoords, { key: 0 }))
                      : (_openBlock(), _createBlock(_component_ExitCoords, { key: 1 }))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}