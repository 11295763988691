
import { computed, defineComponent, ref } from "vue";
import {
  Input,
  Textarea,
  Button,
  Row,
  Col,
  Tooltip,
  Space,
} from "ant-design-vue";

export default defineComponent({
  props: ["npc", "key"],
  emits: ["delete", "update"],
  components: { Input, Textarea, Button, Row, Col, Tooltip, Space },
  setup(props, { emit }) {
    const coords = computed(() => {
      return props.npc?.coords_list;
    });
    const name = ref<string>("");
    const posX = ref<number>(0);
    const posY = ref<number>(0);
    const posZ = ref<number>(0);
    const price = ref<number>(0);
    const description = ref<string>("");
    const onSave = (upd_coord: any) => {
      emit("update", upd_coord);
    };
    const onDelete = (id_npc: number, id_coord: string) => {
      emit("delete", id_npc, id_coord);
    };
    return {
      coords,
      onSave,
      onDelete,
      name,
      posX,
      posY,
      posZ,
      description,
      price,
    };
  },
});
