
import { defineComponent, ref, watch, onMounted } from "vue";
import { Input, Textarea, Button, Row, Col, Tooltip, Checkbox } from "ant-design-vue";
import { TEyeEffects, TTriggerCoords } from "@/types/triggercoords.type";
export default defineComponent({
  components: { Input, Textarea, Button, Row, Col, Tooltip,Checkbox },
  props: {
    triggerCoords: {
      type: Object,
    },
  },
  emits: ["onDelete", "saveChanges"],
  setup(props, { emit }) {
    const _id = ref<string>(props.triggerCoords?._id);
    const X = ref<number>(0);
    const Z = ref<number>(0);
    const nameCoord = ref<string>("б/и");
    const isChanged = ref<boolean>(false);
    const isSaved = ref<boolean>(false);
    const onFail = ref<boolean>(false);
    const description = ref<string>(props.triggerCoords?.description);
    const isEyeEffectEnabled = ref<Boolean>(false);
    const isColoredRandomChange = ref<Boolean>(false);
    const isEyeRandomFreq = ref<Boolean>(false);
    const argb = ref<Number[]>([]);
    const result_obj = ref<TTriggerCoords>({} as TTriggerCoords)
    const toRGB = (e: any) => {
      let color;
      if(typeof e === "string"){
        color = e;
      }else{
        color = e.target.value;
      }
      const {style} = new Option();
      style.color = color;
      console.log(style.color.replace(/[^\d,]/g, "").split(",").map(v => Number((Number(v) /2.55 /100).toFixed(2))).concat([0]))
      argb.value = style.color.replace(/[^\d,]/g, "").split(",").map(v => Number((Number(v) /2.55 /100).toFixed(2))).concat([0]);
    }
    onMounted(() =>
      console.log({
        posX: X.value,
        posZ: Z.value,
        name: nameCoord.value,
        description: description.value,
      })
    );

    onMounted(() => console.log(123));

    const saveChanges = async () => {
      let eye_effects: TEyeEffects = {
      isEnable:isEyeEffectEnabled.value as boolean,
      isColoredRandomChange: isColoredRandomChange.value as boolean,
      isEyeRandomFreq: isEyeRandomFreq.value as boolean,
      colors: argb.value as number[]
      }
      result_obj.value.posX = X.value;
      result_obj.value.posZ = Z.value;
      result_obj.value.name = nameCoord.value;
      result_obj.value.description = description.value;
      result_obj.value.eye_effects = eye_effects;
      console.log(result_obj.value)
      emit("saveChanges", result_obj.value);
      // emit("saveChanges", {
      //   posX: X.value,
      //   posZ: Z.value,
      //   name: nameCoord.value,
      //   description: description.value,
      // });
      X.value = 0;
      Z.value = 0;
      isSaved.value = true;
      setTimeout(() => {
        isSaved.value = false;
      }, 4000);
    };
    watch([X, Z], (nextValue, prevValue) => {
      if (nextValue[0] && prevValue[0]) {
        if (
          (nextValue[0].toString().indexOf(".") != -1 &&
            nextValue[0].toString().length > 8) ||
          (nextValue[1].toString().indexOf(".") != -1 &&
            nextValue[1].toString().length > 8) ||
          parseInt(nextValue[0].toString().split(".")[0]) > 20000 ||
          parseInt(nextValue[1].toString().split(".")[0]) > 20000
        ) {
          isChanged.value = true;
        } else {
          isChanged.value = false;
        }
      }
    });
    return {
      X,
      Z,
      nameCoord,
      isChanged,
      isSaved,
      onFail,
      saveChanges,
      description,
      isEyeEffectEnabled,
      isColoredRandomChange,
      isEyeRandomFreq,
      argb,
      toRGB
    };
  },
});
