
import { defineComponent, onUpdated, ref } from "vue";
import Main from "../components/Main.vue";

export default defineComponent({
  components: {
    Main,
  },
  setup() {},
});
