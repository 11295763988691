
import { Tooltip, Input, Row, Col, Button } from "ant-design-vue";
import { computed, defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
  components: { Tooltip, Input, Row, Col, Button },
  props: ["profile"],
  emits: ["saveChangesProfile"],
  setup(props, { emit }) {
    const server_ip = ref<string>("");
    const steam_id = ref<string>("");
    const discord_name = ref<string>("");
    const discord_real_name = ref<string>("");
    const discord_guild = ref<string>("");
    watchEffect(() => {
      steam_id.value = props?.profile.steam_id;
      discord_name.value = props?.profile.discord_name;
      discord_real_name.value = props?.profile.discord_real_name;
      discord_guild.value = props?.profile.discord_guild;
      server_ip.value = props?.profile.server_ip;
    });
    const updateProfile = () => {
      const upd_profile = {
        steam_id: steam_id.value,
        discord_name: discord_name.value,
        discord_real_name: discord_real_name.value,
        discord_guild: discord_guild.value,
      };
      emit("saveChangesProfile", upd_profile);
    };
    return {
      steam_id,
      discord_name,
      discord_real_name,
      discord_guild,
      server_ip,
      updateProfile,
    };
  },
});
