
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { Tooltip, Input, Row, Col, Button } from "ant-design-vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { fetchApi } from "@/utils/fetch_api";
import { TEntitiesClient } from "../../../types/entitiesclient.type";

export default defineComponent({
  components: { Tooltip, Input, Row, Col, Button, InfoCircleOutlined },
  props: [],
  emits: [],
  setup(props, { emit }) {
    const _id = ref<string>();
    const sounds_npc_guide_allow = ref<string>();
    const sounds_npc_guide_deny = ref<string>();
    const sounds_trigger_trap = ref<string>();
    const sounds_trigger_trap_wrapper = ref<string>();
    const particles_triggers_traps = ref<string>();
    const particles_triggers_traps_extends = ref<string>();
    const images_background_npc_guide = ref<string>();
    const sounds_npc_guide_crossing = ref<string>();
    const players_icons = ref<string>();
    const guides_icons = ref<string>();
    const consist_data = async () => {
      const data: TEntitiesClient = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/content-web`,
        "GET"
      );
      _id.value = data._id;
      sounds_npc_guide_allow.value = data.sounds_npc_guide_allow.join(",");
      sounds_npc_guide_deny.value = data.sounds_npc_guide_deny.join(",");
      sounds_trigger_trap.value = data.sounds_trigger_trap.join(",");
      sounds_trigger_trap_wrapper.value =
        data.sounds_trigger_trap_wrapper.join(",");
      particles_triggers_traps.value = data.particles_triggers_traps.join(",");
      particles_triggers_traps_extends.value =
        data.particles_triggers_traps_extends.join(",");
      images_background_npc_guide.value =
        data.images_background_npc_guide.join(",");
      sounds_npc_guide_crossing.value =
        data.sounds_npc_guide_crossing.join(",");
      players_icons.value = data.players_icons.join(",");
      guides_icons.value = data.guides_icons.join(",");
    };
    watchEffect(() => {
      consist_data();
    });
    const updateAppSettings = async () => {
      const body = {
        _id: _id.value,
        sounds_npc_guide_allow: sounds_npc_guide_allow.value
          ?.split(",")
          .filter((v) => v),
        sounds_npc_guide_deny: sounds_npc_guide_deny.value
          ?.split(",")
          .filter((v) => v),
        sounds_trigger_trap: sounds_trigger_trap.value
          ?.split(",")
          .filter((v) => v),
        sounds_trigger_trap_wrapper: sounds_trigger_trap_wrapper.value
          ?.split(",")
          .filter((v) => v),
        particles_triggers_traps: particles_triggers_traps.value
          ?.split(",")
          .filter((v) => v),
        particles_triggers_traps_extends: particles_triggers_traps_extends.value
          ?.split(",")
          .filter((v) => v),
        images_background_npc_guide: images_background_npc_guide.value
          ?.split(",")
          .filter((v) => v),
        sounds_npc_guide_crossing: sounds_npc_guide_crossing.value
          ?.split(",")
          .filter((v) => v),
        players_icons: players_icons.value?.split(",").filter((v) => v),
        guides_icons: guides_icons.value?.split(",").filter((v) => v),
      };
      const data: TEntitiesClient = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/content-web`,
        "PUT",
        { ...body }
      );
      consist_data();
    };
    return {
      updateAppSettings,
      sounds_npc_guide_allow,
      sounds_npc_guide_deny,
      sounds_trigger_trap,
      sounds_trigger_trap_wrapper,
      particles_triggers_traps,
      particles_triggers_traps_extends,
      images_background_npc_guide,
      sounds_npc_guide_crossing,
      players_icons,
      guides_icons,
    };
  },
});
