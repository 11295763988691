<template>
    <MainGuide />
</template>
<script>
import { defineComponent } from 'vue';
import MainGuide from '../components/guides/MainGuide.vue'
export default defineComponent({
    components: { MainGuide },
    setup() {

    },
})
</script>