
import { defineComponent } from "vue";
import TriggerCoords from "./UI/TriggerCoords.vue";
import ExitCoords from "./UI/ExitCoords.vue";
import {
  Layout,
  LayoutSider,
  LayoutContent,
  Menu,
  MenuItem,
  Row,
  Col,
} from "ant-design-vue";

import { ref } from "vue";
export default defineComponent({
  components: {
    Layout,
    LayoutSider,
    LayoutContent,
    ExitCoords,
    TriggerCoords,
    Menu,
    MenuItem,
    Row,
    Col,
  },
  setup() {
    const selectedKeys = ref<string[]>(["1"]);
    const items = [
      { key: "1", label: "Триггеры", title: "Триггеры" },
      { key: "2", label: "Выход из триггеров", title: "Выход из триггеров" },
    ];
    return { selectedKeys, items };
  },
});
