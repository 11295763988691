
import { defineComponent, ref, watch } from "vue";
import { Input, Textarea, Button, Row, Col, Tooltip,Checkbox, InputNumber } from "ant-design-vue";
import { TExitCoords } from "@/types/exitcoords.type";
export default defineComponent({
  components: { Input, Textarea, Button, Row, Col, Tooltip,Checkbox, InputNumber },
  props: {
    triggerCoords: {
      type: Object,
    },
  },
  emits: ["onDelete", "saveChanges"],
  setup(props, { emit }) {
    const _id = ref<string>(props.triggerCoords?._id);
    const X = ref<number>(0);
    const Y = ref<number>(0);
    const Z = ref<number>(0);
    const nameCoord = ref<string>("б/и");
    const isChanged = ref<boolean>(false);
    const isSaved = ref<boolean>(false);
    const onFail = ref<boolean>(false);
    const isNegative = ref<boolean>(false)
    const isNegativeBlood = ref<boolean>(false)
    const isNegativeHP = ref<boolean>(false)
    const isNegativeUnka = ref<boolean>(false)
    const blood = ref<number>(20)
    const hp = ref<number>(20)
    const unka = ref<number>(5)
    const description = ref<string>(props.triggerCoords?.description);
    const saveChanges = async () => {
      console.log(isNegativeBlood.value)
      console.log(isNegativeUnka.value)
      console.log(isNegativeHP.value)
      const result_obj: TExitCoords = {
        posX: X.value,
        posY: Y.value,
        posZ: Z.value,
        name: nameCoord.value,
        description: description.value,
        isNegative: isNegative.value,
        blood_damage: isNegativeBlood.value ? blood.value : blood.value - 1,
        health_damage: isNegativeHP.value ? hp.value : hp.value - 1,
        unca_effect: isNegativeUnka.value ? unka.value : unka.value - 1,
      }
      // emit("saveChanges", {
      //   posX: X.value,
      //   posY: Y.value,
      //   posZ: Z.value,
      //   name: nameCoord.value,
      //   description: description.value,
      // });
      emit("saveChanges", result_obj);
      X.value = 0;
      Z.value = 0;
      isSaved.value = true;
      setTimeout(() => {
        isSaved.value = false;
      }, 4000);
    };
    watch([X, Y, Z], (nextValue, prevValue) => {
      if (nextValue[0] && prevValue[0]) {
        if (
          (nextValue[0].toString().indexOf(".") != -1 &&
            nextValue[0].toString().length > 8) ||
          (nextValue[1].toString().indexOf(".") != -1 &&
            nextValue[1].toString().length > 8) ||
          parseInt(nextValue[0].toString().split(".")[0]) > 20000 ||
          parseInt(nextValue[1].toString().split(".")[0]) > 20000
        ) {
          isChanged.value = true;
        } else {
          isChanged.value = false;
        }
      }
    });
    return {
      X,
      Y,
      Z,
      nameCoord,
      isChanged,
      isSaved,
      onFail,
      saveChanges,
      description,
      isNegative,
      isNegativeBlood,
      isNegativeHP,
      blood,
      hp,
      isNegativeUnka,
      unka
    };
  },
});
