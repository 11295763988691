
import { defineComponent, onMounted, ref, watch } from "vue";
import Trigger from "@/components/teleport/UI/Trigger.vue";
import TriggerNew from "@/components/teleport/UI/TriggerNew.vue";
import { fetchApi } from "@/utils/fetch_api";
import { Spin } from "ant-design-vue";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: { Trigger, TriggerNew, Spin, LoadingOutlined },
  setup() {
    const triggers = ref([]);
    const isLoading = ref(true);
    onMounted(async () => {
      console.log(triggers);
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/trigger-coords/all`,
        "GET"
      );
      triggers.value = data;
      console.log(triggers.value);
    });
    const onDelete = async (_id: string) => {
      const response = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/trigger-coords/` + _id,
         "DELETE"
      );
      const data = await response;
      triggers.value = data;
    };

    const saveChanges = async (coord: any) => {
      console.log(coord);
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/trigger-coords`,
        "POST",
        coord
      );
      triggers.value = data;
    };

    watch(triggers, (newValue) => {
      isLoading.value = false;
    });

    return { triggers, onDelete, saveChanges, LoadingOutlined, isLoading };
  },
});
