
import { defineComponent, ref, watch } from "vue";
import {
  Input,
  Textarea,
  Button,
  Row,
  Col,
  Tooltip,
  Space,
  Checkbox,
  InputNumber
} from "ant-design-vue";
import { fetchApi } from "@/utils/fetch_api";

export default defineComponent({
  components: { Input, Textarea, Button, Row, Col, Tooltip, Space,Checkbox,
  InputNumber },
  props: {
    triggerCoords: {
      type: Object,
    },
  },
  emits: ["onDelete"],
  setup(props, { emit }) {
    const closedX = ref<number>(props.triggerCoords?.posX);
    const closedZ = ref<number>(props.triggerCoords?.posZ);
    const _id = ref<string>(props.triggerCoords?._id);
    const X = ref<number>(props.triggerCoords?.posX);
    const Y = ref<number>(props.triggerCoords?.posY);
    const Z = ref<number>(props.triggerCoords?.posZ);
    const nameCoord = ref<string>(props.triggerCoords?.name);
    const description = ref<string>(props.triggerCoords?.description);
    const isChanged = ref<boolean>(false);
    const isSaved = ref<boolean>(false);
    const onFail = ref<boolean>(false);
    const isNegative = ref<boolean>(props.triggerCoords?.isNegative)
    const isNegativeBlood = ref<boolean>(props.triggerCoords?.blood_damage > 19 ? true : false)
    const isNegativeHP = ref<boolean>(props.triggerCoords?.health_damage > 19 ? true : false)
    const isNegativeUnka = ref<boolean>(props.triggerCoords?.unca_effect > 4 ? true : false)
    const blood = ref<number>(props.triggerCoords?.blood_damage > 19 ? props.triggerCoords?.blood_damage : 20)
    const hp = ref<number>(props.triggerCoords?.health_damage > 19 ? props.triggerCoords?.health_damage : 20)
    const unka = ref<number>(props.triggerCoords?.unca_effect > 4 ? props.triggerCoords?.unca_effect : 5)
    const saveChanges = async () => {
      console.log( {
            _id: _id.value,
            posX: X.value,
            posY: Y.value,
            posZ: Z.value,
            name: nameCoord.value,
            description: description.value,
            isNegative: isNegative.value,
            blood_damage: isNegativeBlood.value ? blood.value : 19,
            health_damage: isNegativeHP.value ? hp.value : 19,
            unca_effect: isNegativeUnka.value ? unka.value : 4,
          })
      const response = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/teleport/exit-coords`,
        
          "PUT",
         {
            _id: _id.value,
            posX: X.value,
            posY: Y.value,
            posZ: Z.value,
            name: nameCoord.value,
            description: description.value,
            isNegative: isNegative.value,
            blood_damage: isNegativeBlood.value ? blood.value : 19,
            health_damage: isNegativeHP.value ? hp.value : 19,
            unca_effect: isNegativeUnka.value ? unka.value : 4,
          },
        
      );
      const data = await response;
      if (data) {
        X.value = data.posX;
        Y.value = data.posY;
        Z.value = data.posZ;
        isSaved.value = true;
        setTimeout(() => {
          isSaved.value = false;
        }, 4000);
      } else {
        onFail.value = true;
        setTimeout(() => {
          onFail.value = false;
        }, 4000);
      }
    };
    const onDelete = () => {
      emit("onDelete", _id.value);
    };
    watch([X, Z], (nextValue, prevValue) => {
      if (
        (nextValue[0].toString().indexOf(".") != -1 &&
          nextValue[0].toString().length > 8) ||
        (nextValue[1].toString().indexOf(".") != -1 &&
          nextValue[1].toString().length > 8) ||
        parseInt(nextValue[0].toString().split(".")[0]) > 20000 ||
        parseInt(nextValue[1].toString().split(".")[0]) > 20000
      ) {
        isChanged.value = true;
      } else {
        isChanged.value = false;
      }
    });
    return {
      X,
      Y,
      Z,
      nameCoord,
      isChanged,
      isSaved,
      onFail,
      saveChanges,
      onDelete,
      description,
      isNegative,
      isNegativeBlood,
      isNegativeHP,
      blood,
      hp,
      isNegativeUnka,
      unka
    };
  },
});
