
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
  watchEffect,
} from "vue";
import {
  Layout,
  LayoutContent,
  LayoutSider,
  MenuItem,
  Menu,
  Button,
  notification,
  Modal,
  Input,
  Divider,
  Row,
  Col,
} from "ant-design-vue";
import NPC from "./UI/NPC.vue";
import Coord from "./UI/Coord.vue";
import { fetchApi } from "@/utils/fetch_api";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    NPC,
    Modal,
    Coord,
    Layout,
    LayoutContent,
    MenuItem,
    Menu,
    LayoutSider,
    Button,
    notification,
    Input,
    Divider,
    Row,
    Col,
  },
  setup() {
    const store = useStore();
    const selectedKeys = ref<string[]>(["1"]);
    const npc_array = ref<any[]>([]);
    const n_menu_id = ref<string>("");
    const n_name = ref<string>("");
    const n_posX = ref<number>(0);
    const n_posY = ref<number>(0);
    const n_posZ = ref<number>(0);
    const open = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const npc_current = computed(() => {
      return npc_array.value.filter(
        ({ menu_id }) => menu_id == selectedKeys.value[0]
      )[0];
    });

    const getGuides = async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/npc-web`,
        "GET"
      );
      npc_array.value = data;
      console.log(npc_current.value);
      console.log(123);
      const equipment = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/consolidator-web/clothing`,
        "GET"
      );

      store.dispatch("setAccessibleEquipment", equipment);
    };

    onMounted(() => getGuides()); //
    const updated = async () => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/npc-web`,
        "GET"
      );
      npc_array.value = data;
    };
    const handleDelete = async (id_npc: number, id_coord: number) => {
      await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/npc-coords?id_npc=${id_npc}&id_coord=${id_coord}`,
        "DELETE"
      );
      updated();
    };
    const handleUpdate = async (upd_coords: any) => {
      const response = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/coord`,
        
          "PUT",upd_coords
      );
      if (response) {
        notification.open({ message: "Данные обновлены" });
      } else {
        notification.open({ message: "Не удалось обновить" });
      }
      updated();
    };
    const handleUpdateNpc = async (upd_npc: any) => {
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/npc`,
        "PUT",
        upd_npc
      );
      if (data) {
        await getGuides();
        notification.open({ message: "Данные обновлены" });
      } else {
        notification.open({ message: "Не удалось обновить" });
      }
      updated();
    };
    const handleOkNpc = async () => {
      confirmLoading.value = true;
      const new_npc = {
        menu_type: npc_array?.value[0]?.menu_type ?? 609015,
        menu_id: n_menu_id.value,
        posX: Number(n_posX.value),
        posZ: Number(n_posZ.value),
      };
      const data = await fetchApi(
        `${process.env.VUE_APP_BACKEND_URL}/guides/npc`,
        "POST",
        new_npc
      );
      if (data) {
        notification.open({
          message: "Проводник успешно создан",
        });
        updated();
      } else {
        notification.open({
          message: `Ошибка ${data}`,
        });
      }
      open.value = false;
      confirmLoading.value = false;
    };
    const showModal = () => {
      open.value = true;
    };
    const menuItems = computed(() => {
      return npc_array.value.map(({ menu_id, name }) => {
        return {
          key: menu_id,
          label: `${name} (${menu_id})`,
          title: name,
        };
      });
    });

    watchEffect(() => {
      // Код, который должен выполняться при изменении npc_array
    });
    return {
      menuItems,
      selectedKeys,
      npc_array,
      npc_current,
      updated,
      handleDelete,
      handleUpdate,
      handleUpdateNpc,
      handleOkNpc,
      n_menu_id,
      n_name,
      n_posX,
      n_posY,
      showModal,
      n_posZ,
      open,
      confirmLoading,
    };
  },
});
