import { createI18n } from "vue-i18n";

const messages = {
  en: {
    Body: "Тело",
    Back: "Спина",
    Gloves: "Перчатки",
    Mask: "Маска",
    Vest: "Жилет",
    Headgear: "Головной убор",
    Hips: "Бедра",
    Legs: "Ноги",
    Armband: "Повязка на руку",
    Feet: "Стопы",
    Eyewear: "Очки",
    Belt: "Пояс",
    back: "Ремень",
    Ears: "Уши",
    head: "Голова",
  },
};

const i18n = createI18n({
  locale: "en",
  messages,
});

export default i18n;
