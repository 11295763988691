<template>
    <MainTeleport />
</template>
<script>
import { defineComponent } from 'vue';
import MainTeleport from '../components/teleport/MainTeleport.vue'
export default defineComponent({
    components:{MainTeleport},
    setup() {
        
    },
})
</script>